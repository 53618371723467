import React from 'react'

import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/Layout'
import { Divider } from '../styledComponents'
import { mq, spacing } from '../utils/presets'
import SEO from '../components/SEO'

const Section = styled(Divider)`
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    border: ${(props) => (props.isFirst ? '0px solid' : '')};
    ${spacing(
        [
            [0, 0, 0],
            [10, 10, 60],
        ],
        'margin'
    )}
    ${spacing(
        [
            [40, 30, 30],
            [0, 0, 0],
        ],
        'padding'
    )}
    ${(props) =>
        props.isFirst ? spacing([0, 30, 60], 'margin-top') : undefined}
`

const Column = styled.div`
    flex-basis 100%;
    ${mq.tablet} {
        flex-basis: 50%;
    }
`

const Footer = styled(Divider)`
    ${spacing(
        [
            [40, 30, 30],
            [10, 10, 60],
        ],
        'padding'
    )}
`

const ImageContainer = styled.div`
    ${spacing([10, 0, 0], 'margin-bottom')}
    ${mq.tablet} {
        ${spacing([0, 10, 90], 'margin-right')}
    }
`

export default () => {
    const data: getInfoPage = useStaticQuery(graphql`
        query getInfoPage {
            infoPage: mdx(fileAbsolutePath: { glob: "**/pages/info.md" }) {
                id
                frontmatter {
                    title
                    seoDescription
                    sections {
                        textColumn
                        imageColumn {
                            childImageSharp {
                                fluid(maxWidth: 1200, quality: 90) {
                                    aspectRatio
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    footer
                }
            }
        }
    `)
    const { seoDescription, sections, footer } = data.infoPage.frontmatter
    const sectionWithImage = sections.find((s) => s.imageColumn)
    return (
        <Layout>
            <SEO
                title="Info"
                description={seoDescription}
                image={
                    sectionWithImage &&
                    sectionWithImage.imageColumn.childImageSharp.fluid.src
                }
            />
            {sections.map((s, idx) => (
                <Section key={idx} isFirst={idx === 0}>
                    <Column>
                        {s.imageColumn && (
                            <ImageContainer>
                                <Img
                                    fluid={s.imageColumn.childImageSharp.fluid}
                                />
                            </ImageContainer>
                        )}
                    </Column>
                    <Column>
                        <MDXRenderer>{s.textColumn}</MDXRenderer>
                    </Column>
                </Section>
            ))}

            <Footer>
                <MDXRenderer>{footer}</MDXRenderer>
            </Footer>
        </Layout>
    )
}
